// Generated by Framer (b75150a)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["z92h_olsm"];

const serializationHash = "framer-6cR9W"

const variantClassNames = {z92h_olsm: "framer-v-b1frzt"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({devLogo, height, id, width, ...props}) => { return {...props, gnbTiI2yI: devLogo ?? props.gnbTiI2yI} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;devLogo?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, gnbTiI2yI, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "z92h_olsm", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} background={{alt: "", fit: "fit", sizes: componentViewport?.width || "100vw", ...toResponsiveImage(gnbTiI2yI), ...{ positionX: "center", positionY: "center" }}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-b1frzt", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"z92h_olsm"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-6cR9W.framer-1vxt6dc, .framer-6cR9W .framer-1vxt6dc { display: block; }", ".framer-6cR9W.framer-b1frzt { height: 90px; position: relative; width: 183px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 90
 * @framerIntrinsicWidth 183
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"gnbTiI2yI":"devLogo"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramertAShZxnxH: React.ComponentType<Props> = withCSS(Component, css, "framer-6cR9W") as typeof Component;
export default FramertAShZxnxH;

FramertAShZxnxH.displayName = "Developer logo";

FramertAShZxnxH.defaultProps = {height: 90, width: 183};

addPropertyControls(FramertAShZxnxH, {gnbTiI2yI: {title: "Dev Logo", type: ControlType.ResponsiveImage}} as any)

addFonts(FramertAShZxnxH, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})